import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const inquiryAPI = {
  getQuTaByInquiry: params => getNoCatch(`${topic.inquiryTopic}/getQuTaByInquiry`, params),
  get_inquiry: params => getNoCatch(`${topic.inquiryTopic}/get_inquiry`, params),
  add_inquiry: params => postNoCatch(`${topic.inquiryTopic}/add_inquiry`, params),
  copy_inquiry: params => postNoCatch(`${topic.inquiryTopic}/copy_inquiry`, params),
  edit_inquiry: params => postNoCatch(`${topic.inquiryTopic}/edit_inquiry`, params),
  delete_inquiry_by_id: params => getNoCatch(`${topic.inquiryTopic}/delete_inquiry_by_id`, params),
  get_inquiry_by_id: params => getNoCatch(`${topic.inquiryTopic}/get_inquiry_by_id`, params),
  addProdList: params => getNoCatch(`${topic.inquiryTopic}/addProdList`, params),
  getProdList: params => getNoCatch(`${topic.inquiryTopic}/getProdList`, params),
  addPartList: params => getNoCatch(`${topic.inquiryTopic}/addPartList`, params),
  getPartList: params => getNoCatch(`${topic.inquiryTopic}/getPartList`, params),
  add_consult: params => postNoCatch(`${topic.consultTopic}/add_consult`, params),
  addPriceConsultList: params => postNoCatch(`${topic.priceConsultTopic}/addPriceConsultList`, params),
  getQutcSmpls: params => getNoCatch(`${topic.inquiryTopic}/getQutcSmpls`, params),
  getQutbSmpls: params => getNoCatch(`${topic.inquiryTopic}/getQutbSmpls`, params),
  getFequSmpls: params => getNoCatch(`${topic.inquiryTopic}/getFequSmpls`, params),
  getToDyjRqtaList: params => getNoCatch(`${topic.openTopic}/dyj/api/getToDyjRqtaList`, params),
  inquPush9005Total: topic.inquiryTopic + '/inquPush9005Total',
  getInquPush: topic.inquiryTopic + '/getInquPush',
  deleteInquPush: topic.inquiryTopic + '/deleteInquPush'
};
