<template>
  <el-card>
    <el-tabs v-model="activeName">
      <el-tab-pane name="first">
        <el-badge slot="label" :value="tableData1.length" class="item"> 价格咨询单 </el-badge>
        <DynamicUTable ref="dynamicUTable1" :tableData="tableData1" :columns="tableProperties" :need-pagination="false" :need-check-box="false">
          <template v-slot:operation="scope">
            <el-button type="primary" size="mini" @click="dbClickJp174(scope.row)">查看详情</el-button>
            <el-button type="success" size="mini" @click="toCompleted(scope.row)">已处理</el-button>
          </template>
        </DynamicUTable>
      </el-tab-pane>
      <el-tab-pane name="second">
        <el-badge slot="label" :value="tableData2.length" class="item"> 订单咨询单 </el-badge>
        <DynamicUTable ref="dynamicUTable2" :tableData="tableData2" :columns="tableProperties" :need-pagination="false" :need-check-box="false">
          <template v-slot:operation="scope">
            <el-button type="primary" size="mini" @click="dbClickJp175(scope.row)">查看详情</el-button>
            <el-button type="success" size="mini" @click="toCompleted(scope.row)">已处理</el-button>
          </template>
        </DynamicUTable>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import { getNoCatch, postNoCatch } from '@api/request';
import bus from '@/components/common/bus';
import { getDateNoTime } from '@assets/js/dateUtils';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { inquiryAPI } from '@api/modules/inquiry';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'inquPush9005',
  components: { DynamicUTable },
  data() {
    return {
      activeName: 'first',
      tableData1: [],
      tableData2: [],
      loading: true,
      tableProperties: [
        {
          prop: 'consult_push_date',
          itemType: 'input',
          label: '下发时间',
          input: true,
          sortable: false,
          labelWidth: 180,
          formatter: val => getDateNoTime(val, false)
        },
        { prop: 'consult_push_text', itemType: 'input', label: '文本', input: true, sortable: false, widthAuto: true },
        { label: '操作', prop: 'operation', itemType: 'input', input: false, sortable: false, labelWidth: 220 }
      ]
    };
  },
  mounted() {
    this.initData();
    bus.$on('getRequPush9005Init', msg => {
      this.initData();
    });
  },
  methods: {
    initData() {
      this.getPushList();
    },
    //获取数据
    getPushList() {
      this.loading = true;
      getNoCatch(inquiryAPI.getInquPush, { user_id: this.$cookies.get('userInfo').user_id }).then(({ data }) => {
        this.tableData1 = data.list1;
        this.tableData2 = data.list2;
        setTimeout(() => {
          this.loading = false;
        }, 200);
      });
    },
    //双击跳转至详情页
    dbClickJp174(row) {
      this.jump('/price_consult_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: 174,
            form_id: row.form_id
          })
        )
      });
    },
    dbClickJp175(row) {
      this.jump('/consult_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: 175,
            form_id: row.form_id
          })
        )
      });
    },
    //完成
    toCompleted(row) {
      this.$confirm('点击完成后，此条消息将会消失!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          postNoCatch(inquiryAPI.deleteInquPush, {
            consult_push_id: row.consult_push_id
          }).then(({ data }) => {
            this.$message.success('操作成功!');
            this.getPushList();
            //刷新首页推送提醒数量
            bus.$emit('getPermInfo');
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-tabs__item {
  margin-top: 10px;
}
</style>
